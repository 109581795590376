import React, { useState } from "react";
import { Pagination } from "react-bootstrap";

export const Paginacion = ({ cantidad, onClick }) => {
  const [active, setActive] = useState(0);
  // console.log({ active });
  const cantidadPaginas = new Array(Math.ceil(cantidad / 10)).fill();
  // console.log({ cantidadPaginas: cantidad, 1: (cantidad / 10).toFixed(0) });
  return (
    <Pagination
      size="sm"
      style={{
        margin: "0",
        padding: "0.5rem 0 0 0",
      }}
    >
      {cantidadPaginas.map((_, i) => (
        <Pagination.Item
          id={i}
          key={i}
          active={i === active}
          onClick={(e) => {
            const idpagina = +e.target.id;
            setActive(idpagina);
            onClick(idpagina);
          }}
        >
          {i + 1}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};

import { Button, Modal, Offcanvas } from "react-bootstrap";

export const EliminarModal = ({
  show,
  handleCancelar,
  handleAceptar,
  mensaje,
  entidad,
}) => {
  return (
    <Modal centered show={show} onHide={handleCancelar} animation={false}>
      <div style={{ border: "10px ridge #f33c", borderRadius: "0.4rem" }}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar {entidad}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{mensaje}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelar}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAceptar}>
            Aceptar
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export const EliminarCanvas = ({
  show,
  handleCancelar,
  handleAceptar,
  mensaje,
  entidad,
}) => {
  return (
    <>
      <Offcanvas show={show} onHide={handleCancelar} placement={"down"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Eliminar {entidad}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{mensaje}</Offcanvas.Body>
        <Button variant="secondary" onClick={handleCancelar}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleAceptar}>
          Aceptar
        </Button>
      </Offcanvas>
    </>
  );
};

import Axios from "axios";

export const urlApi = process.env.REACT_APP_API_URL;
const Authorization = localStorage.getItem("token") ?? "";
export const UseNegociosRest = async () => {
  const restNegocios = await Axios({
    url: urlApi + "negocios",
  });

  return restNegocios.data;
};

export const UseProductosRest = async (params) => {
  const { pagina, negocio, buscar } = params || {};
  const restProductos = await Axios({
    url: `${urlApi}productos/?limit=10&offset=${pagina ?? 0}&negocio=${
      negocio ?? 0
    }&buscar=${buscar ?? ""}`,
  });
  return restProductos.data;
};

export const UseCategoriasRest = async (negocio) => {
  try {
    const categorias = await Axios({
      url: urlApi + "productos/solocategorias/" + negocio,
    });

    return categorias.data;
  } catch (error) {
    // console.log({ error });
  }
};

export const UseAddProductosRest = async (productos) => {
  const formData = new FormData();
  // console.log({ productos });
  try {
    for (const producto in productos) {
      if (producto === "imagenes") {
        productos[producto].forEach((im) => formData.append(producto, im));
        formData.append(producto, productos[producto]);
      } else formData.append(producto, productos[producto]);
    }
    // console.log({ formData: [...formData.values()], productos });
    const restProductos = await Axios.post(
      urlApi + "productos/agregar",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization,
        },
      }
    );

    return { data: restProductos.data };
  } catch (error) {
    // console.log("UseAddProductosRest", { error });
    const Error = { error: error.response.data };
    return Error;
  }
  // console.log("productos", productos);
};

export const UseEditProductosRest = async (productos) => {
  const formData = new FormData();

  // console.log("productos", productos);
  for (const producto in productos) {
    if (producto === "imagenes") {
      productos[producto].forEach((im) => formData.append(producto, im));
      formData.append(producto, productos[producto]);
    } else formData.append(producto, productos[producto]);
  }
  try {
    const restProductos = await Axios.put(
      urlApi + "productos/editar",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization,
        },
      }
    );

    return restProductos.data;
  } catch (error) {
    alert("UseEditProductosRest");
    // console.log({ error });
  }
};

export const UseDelImagenProductoRest = async (idimagenes) => {
  try {
    const restProductos = await Axios.delete(urlApi + "productos/imagenes", {
      headers: {
        "Content-Type": "application/json",
        Authorization,
      },
      data: {
        idimagenes,
      },
    });

    return restProductos;
  } catch (error) {
    // console.log("UseDelImagenProductoRest", { error });
  }
};

export const UseDelProductoRest = async (idproducto) => {
  try {
    const restProductos = await Axios.delete(
      `${urlApi}productos/${idproducto}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization,
        },
      }
    );

    return restProductos;
  } catch (error) {
    // console.log("UseDelProductoRest", { error });
  }
};
export const UseLoginRest = async (usuario) => {
  try {
    const { data } = await Axios.post(`${urlApi}api/v1/login`, usuario, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log({ data });
    return data;
  } catch (error) {
    // alert("UseLoginRest");
    // console.log({ error });
    return { error: error.response.data.error };
  }
};

export const postAddCategoria = async (nuevaCategoria) => {
  try {
    const { data } = await Axios.post(`${urlApi}categorias/`, nuevaCategoria, {
      headers: {
        "Content-Type": "application/json",
        Authorization,
      },
    });
    // console.log({ data });
    return data;
  } catch (error) {
    // alert("UseLoginRest");
    // console.log({ error });
    return { error: error.response.data.error };
  }
};

export const getCategoriasByNegocio = async (idnegocio) => {
  if (!idnegocio) return;
  try {
    const categorias = await Axios.get(`${urlApi}categorias/${idnegocio}`);

    return categorias.data;
  } catch (error) {
    // console.log({ error });
  }
};

export const delCategoriasByCategoria = async (idcategoria) => {
  if (!idcategoria) return;
  try {
    const categorias = await Axios.delete(
      `${urlApi}categorias/${idcategoria}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization,
        },
      }
    );
    const error = categorias?.data?.error;
    error && alert(error);
    return categorias.data;
  } catch (error) {
    // console.log({ error });
  }
};

export const putCategoriasByCategoria = async (categoria) => {
  // console.log({ categoria });
  if (!categoria) return;
  try {
    const categorias = await Axios.put(
      `${urlApi}categorias/${categoria?.id_categoria}`,
      categoria,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization,
        },
      }
    );
    const error = categorias?.data?.error;
    error && alert(error);
    return categorias.data;
  } catch (error) {
    // console.log({ error });
  }
};

import { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { UseLoginRest } from "../servicios/api";

const BackgroundLoginPage = styled.div`
  background-image: url("loginBackground.png");
  background-size: cover;
  height: 100vh;
`;

const BlurBackground = styled.div`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  height: 100vh;
`;

const CenterDiv = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`;

const CardLogin = styled(Card)`
  width: 25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 2rem;
`;

const FormPadding = styled.div`
  padding: 1rem 0;
`;

const LoginButton = styled(Button)`
  margin: 1rem 0 0.3rem 0;
  width: 10rem;
  background-color: #008000;
  border-color: #008000;
`;

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const initForm = {
    user: "",
    password: "",
  };
  const [form, setForm] = useState(initForm);
  
  const handleLogin = async () => {
    setLoading(true);
    const { token, nombre, error } = await UseLoginRest(form);

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("nombre", nombre);
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else {
      setLoading(false);
      alert(error);
    }
  };
  if(loading){
    return <BackgroundLoginPage>
            <BlurBackground>
              <CenterDiv>
                <CardLogin>
                  <img src="logo_provex.png" alt="" />
                  <Spinner animation="grow" variant='success' style={{ margin:'1rem'}} />
                  </CardLogin>
              </CenterDiv>
            </BlurBackground>
          </BackgroundLoginPage>
  }else{
  return (
    <BackgroundLoginPage>
      <BlurBackground>
        <CenterDiv>
          <CardLogin>
            <img src="logo_provex.png" alt="" />
            <FormPadding>
              <Form.Control
                size="lg"
                style={{ margin: "1rem 0" }}
                type="text"
                placeholder="Usuario"
                value={form.user}
                onChange={(e) => setForm({ ...form, user: e.target.value })}
              />
              <Form.Control
                size="lg"
                style={{ margin: "1rem 0 0 0" }}
                type="password"
                placeholder="Contraseña"
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
            </FormPadding>
              <LoginButton variant="success" onClick={handleLogin}>
                Ingresar
              </LoginButton>
          </CardLogin>
        </CenterDiv>
      </BlurBackground>
    </BackgroundLoginPage>
  );
}};
import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
`;
export const IconAdd = () => (
  <StyledSvg
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M514 114.4c-220 0-398.8 178.9-398.8 398.8S294 912 514 912s398.8-178.9 398.8-398.8S734 114.4 514 114.4z m0 754.9c-196.4 0-356.1-159.8-356.1-356.1S317.6 157.1 514 157.1s356.1 159.8 356.1 356.1S710.4 869.3 514 869.3z" />
    <path d="M751.4 491.8h-216v-216c0-11.8-9.6-21.4-21.4-21.4s-21.4 9.6-21.4 21.4v216h-216c-11.8 0-21.4 9.6-21.4 21.4 0 11.8 9.6 21.4 21.4 21.4h216v216c0 11.8 9.6 21.4 21.4 21.4s21.4-9.6 21.4-21.4v-216h216c11.8 0 21.4-9.6 21.4-21.4-0.1-11.8-9.6-21.4-21.4-21.4z" />
  </StyledSvg>
);

import React, { useEffect, useState } from "react";
import { Table, Row, Col, Form, Button } from "react-bootstrap";
import { urlApi, UseNegociosRest, UseProductosRest } from "../servicios/api";
import { CarruselImagenes } from "./carruselImagenes";
import ModalAgregarProductos from "./ModalAgregaProductos";
import ModalEditarCategorias from "./ModalEditarCategorias";
import ModalEditarProductos from "./ModalEditarProductos";
import { NavbarSidebar } from "./NavbarSidebar";
import { Paginacion } from "./paginacion";

// import { Paginacion } from "./paginacion";

export const TablaProductos = () => {
  const [listaProductos, setListaProductos] = useState([]);
  const [NombreColumnas, setNombreColumnas] = useState([]);
  const [UnidadesNegocio, setUnidadesNegocio] = useState([]);
  const [selectUnidadNegocio, setSelectUnidadNegocio] = useState();
  const [editarProducto, setEditarProducto] = useState();
  // console.log({ UnidadesNegocio, selectUnidadNegocio });
  const [show, setShow] = useState(false);
  const [buscar, setBuscar] = useState("");
  const [carrusel, setCarrusel] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (producto) => {
    setEditarProducto(producto);
    setShow(true);
  };

  const refresh = () => fetchData();

  const [cantidad, setcantidad] = useState(0);
  const [pagina, setPagina] = useState(0);
  // console.log({ pagina });
  const fetchData = async () => {
    const { datos: productos, cantidad: q } = await UseProductosRest({
      pagina,
      negocio: selectUnidadNegocio?.id ?? "0",
      buscar,
    });
    setcantidad(q);
    setProductoBuscado(productos);
    setListaProductos(productos);

    const {
      idnegocio,
      idcategoria,
      id_imagen,
      idproducto,
      imagen,
      ...auxProductos
    } = productos[0];
    productos && setNombreColumnas(Object.keys(auxProductos));
  };
  const getUnidadesNegocio = async () => {
    const negocios = await UseNegociosRest();
    setUnidadesNegocio(negocios);
    // console.log({ negocios }, "color:red");
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, selectUnidadNegocio]);
  useEffect(() => {
    getUnidadesNegocio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (buscar === "") {
      fetchData();
      setmbuscar("");
    }
  }, [buscar]);

  listaProductos && console.log({ listaProductos });

  const onClickUnidadesNegocio = (e) => {
    // console.log({ onClickUnidadesNegocio: e.target.value });
    setSelectUnidadNegocio(UnidadesNegocio.find((n) => n.id == e.target.value));
    setCarrusel(false);
    setPagina(0);
  };

  const [productoBuscado, setProductoBuscado] = useState([...listaProductos]);

  const productosRegistrados = `${cantidad} productos`;
  const productosRegistradosBuscar =
    buscar &&
    `Hay ${cantidad} productos codigo, descripcion o categoria para la palabra ${buscar}`;
  const [mbuscar, setmbuscar] = useState("");
  const [showModalCategorias, setShowModalCategorias] = useState(false);
  const [pantalla, setPantalla] = useState("productos"); // categoria productos
  return (
    <>
      <NavbarSidebar setPantalla={setPantalla}>
        <Row>
          <Col>
            <Form.Select
              aria-label="Default select example"
              onChange={onClickUnidadesNegocio}
            >
              <option key={"todos"} value={"todos"}>
                Todos los productos
              </option>
              {UnidadesNegocio.map((negocio, i) => (
                <option key={i} value={negocio.id}>
                  {negocio.nombre}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col style={{ textAlign: "center" }}>
            {buscar && mbuscar
              ? productosRegistradosBuscar
              : productosRegistrados}
          </Col>
          <Col>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Ingrese codigo, nombre o categoria"
                className="me-2"
                aria-label="Search"
                value={buscar}
                onChange={(e) => setBuscar(e.target.value)}
              />
              <Button
                variant="outline-success"
                onClick={() => {
                  // console.log({ buscar });
                  fetchData();
                  setmbuscar(buscar);
                }}
              >
                Buscar
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={10} className="my-2">
            <Button onClick={(_) => setShowModalCategorias(true)}>
              Categorias
            </Button>
          </Col>
          <Col
            style={{
              textAlign: "end",
              alignSelf: "end",
            }}
          >
            <ModalAgregarProductos />
          </Col>
        </Row>
      </NavbarSidebar>
      <div style={{ padding: "11rem 2rem" }}>
        {pantalla === "productos" && (
          <Table responsive striped bordered hover size="sm">
            <thead>
              <tr>
                {NombreColumnas.map((NombreColumna, i) => (
                  <th key={i}>
                    {NombreColumna.toUpperCase() === "CARRUSEL" ? (
                      <Form.Check
                        type={"checkbox"}
                        label={"CARRUSEL"}
                        value={carrusel}
                        checked={carrusel}
                        onChange={(e) =>
                          setCarrusel(e.target.value === "false")
                        }
                      />
                    ) : (
                      NombreColumna.toUpperCase()
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {productoBuscado.map((producto, i) => (
                <tr
                  key={i}
                  onClick={(e) => handleShow(producto)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {NombreColumnas.map((NombreColumna, i) =>
                    Array.isArray(producto[NombreColumna]) ? (
                      NombreColumna === "url_imagen" ? (
                        <td style={{ width: "12rem" }} key={i}>
                          {producto["url_imagen"] && (
                            <CarruselImagenes
                              Imagenes={producto.imagen}
                              nombres={""}
                            />
                          )}
                        </td>
                      ) : (
                        <td key={i}>
                          <div
                            style={{ overflowY: "auto", maxHeight: "10rem" }}
                          >
                            {producto[NombreColumna].join(", \n")}
                          </div>
                        </td>
                      )
                    ) : (
                      <td key={i}>
                        {NombreColumna === "url_imagen" ? (
                          <img
                            alt={producto["url_imagen"]}
                            src={urlApi + producto["url_imagen"]}
                            style={{ width: "10rem" }}
                          />
                        ) : (
                          <div
                            style={{ overflowY: "auto", maxHeight: "10rem" }}
                          >
                            {producto[NombreColumna]}
                          </div>
                        )}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Paginacion cantidad={cantidad} onClick={(pag) => setPagina(pag)} />
        {pantalla === "categorias" && <div>asd</div>}

        {show && (
          <ModalEditarProductos
            producto={editarProducto}
            handleClose={handleClose}
            show={show}
            refresh={refresh}
          />
        )}

        <ModalEditarCategorias
          show={showModalCategorias}
          handleClose={(_) => setShowModalCategorias(false)}
        />
      </div>
    </>
  );
};

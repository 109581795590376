import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Container, Col, Row } from "react-bootstrap";
import {
  UseCategoriasRest,
  UseDelImagenProductoRest,
  UseDelProductoRest,
  UseEditProductosRest,
  UseNegociosRest,
} from "../servicios/api";
import { CarruselImagenes } from "./carruselImagenes";
import styled from "styled-components";
import { EliminarModal } from "./ModalEliminar";

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 70%;
  }
`;

function ModalEditarProductos({ producto, show, handleClose, refresh }) {
  const [unidadesNegocios, setUnidadesNegocios] = useState();
  const [categorias, setCategorias] = useState();
  const [Producto, setProducto] = useState(
    JSON.parse(JSON.stringify(producto))
  );

  // console.log({ categorias });
  // console.log({ producto });
  const handleSubmit = async (event) => {
    event.preventDefault();

    const res = await UseEditProductosRest(Producto);
    const idEliminarImagenes = Producto.id_imagen.filter(
      (id) => !Producto.imagen.map((i) => i.id).includes(id)
    );
    if (idEliminarImagenes.length)
      await UseDelImagenProductoRest(idEliminarImagenes);
    // console.log({ idEliminarImagenes });

    refresh();
    handleClose();
  };

  const fetchData = async () => {
    const negocios = await UseNegociosRest();
    setUnidadesNegocios(negocios);
    const Categorias = await UseCategoriasRest(producto.negocio);
    setCategorias(Categorias);
  };
  // console.log({ Producto });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log({ unidadesNegocios });
  const [deleteImagenes, setdeleteImagenes] = useState([]);

  useEffect(() => {
    // console.log({ deleteImagenes });
    ((imagen) => {
      setProducto({ ...Producto, imagen });
    })(
      producto?.imagen?.filter(
        (im) => !deleteImagenes?.map((a) => a?.nombre).includes(im.nombre)
      )
    );
  }, [deleteImagenes]);
  // console.log("home", { Producto });

  const [showEliminarProducto, setShowEliminarProducto] = useState(false);

  const handleEliminarProducto = async () => {
    await UseDelProductoRest(producto.idproducto);
    refresh();
    handleClose();
  };
  const MAXSIZEFILE = 1048576;
  const [validFile, setvalidFile] = useState(true);
  return (
    <StyledModal show={show} onHide={handleClose} dialogClassName="modal-2w">
      <StyledModal.Header closeButton>
        <StyledModal.Title>Editar Productos</StyledModal.Title>
      </StyledModal.Header>
      <Container>
        <Row>
          <Col xs={6}>
            <Form>
              <StyledModal.Body>
                <Container>
                  <Form.Select
                    value={Producto.idnegocio}
                    onChange={(e) => {
                      setProducto({
                        ...Producto,
                        idnegocio: e.target.value,
                      });
                    }}
                  >
                    {unidadesNegocios &&
                      unidadesNegocios.map((negocio) => (
                        <option
                          value={negocio.id}
                          key={JSON.stringify(negocio)}
                        >
                          {negocio.nombre}
                        </option>
                      ))}
                  </Form.Select>
                  <br />
                  <Form.Floating className="mb-3">
                    <Form.Control
                      maxLength="10"
                      id="Codigo"
                      type="text"
                      value={Producto.codigo}
                      onChange={(e) =>
                        setProducto({
                          ...Producto,
                          codigo: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="Codigo">Codigo</label>
                  </Form.Floating>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      maxLength="100"
                      id="Nombre"
                      type="text"
                      value={Producto.nombre}
                      onChange={(e) =>
                        setProducto({
                          ...Producto,
                          nombre: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="Nombre">Nombre</label>
                  </Form.Floating>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      maxLength="1500"
                      id="Descripcion"
                      as="textarea"
                      style={{ height: "100%" }}
                      type="text"
                      value={Producto.descripcion}
                      onChange={(e) =>
                        setProducto({
                          ...Producto,
                          descripcion: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="Descripcion">Descripcion</label>
                  </Form.Floating>
                  <Form.Select
                    value={Producto.idcategoria}
                    onChange={(e) => {
                      setProducto({
                        ...Producto,
                        idcategoria: e.target.value,
                      });
                    }}
                  >
                    {categorias?.length &&
                      categorias?.map((categoria) => (
                        <option
                          value={categoria.id_categoria}
                          key={JSON.stringify(categoria)}
                        >
                          {categoria.nombre_categoria_padre}
                          {" -> "}
                          {categoria.nombre_categoria}
                        </option>
                      ))}
                  </Form.Select>
                  <br />
                </Container>
              </StyledModal.Body>
            </Form>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <Container>
              <Row xs={10}>
                {producto.url_imagen && (
                  <CarruselImagenes
                    width={200}
                    height={200}
                    Imagenes={Producto.imagen}
                    btnDel={true}
                    onDelete={setdeleteImagenes}
                    nuevasImagenes={Producto.imagenes}
                  />
                )}
              </Row>
              <br />
              <Row>
                <Form.Group controlId="Imagenes" className="mb-3">
                  <Form.Label>Cargar imagenes</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".png,.jpg,.jpeg,.webp"
                    multiple
                    invalid={!validFile + ""}
                    onChange={(e) => {
                      const imagenes = Array.from(e.target.files);
                      // console.log({ imagenes });
                      const auxValidFiles = imagenes.some(
                        (a) => a.size >= MAXSIZEFILE
                      );
                      if (auxValidFiles) setvalidFile(auxValidFiles);
                      else
                        setProducto({
                          ...Producto,
                          imagenes: Array.from(e.target.files),
                        });
                    }}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Check
                  type={"checkbox"}
                  label={"Producto en carrusel"}
                  value={!!+Producto.carrusel}
                  checked={!!+Producto.carrusel}
                  onChange={(e) => {
                    setProducto({ ...Producto, carrusel: !Producto.carrusel });
                  }}
                />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <EliminarModal
        show={showEliminarProducto}
        entidad={"producto"}
        mensaje={`¿Desea eliminar el producto ${producto.codigo} - ${producto.nombre}?`}
        handleAceptar={handleEliminarProducto}
        handleCancelar={(_) => setShowEliminarProducto(false)}
      />
      <StyledModal.Footer>
        <Container>
          <Row>
            <Col md={8}>
              <Button
                variant="danger"
                onClick={(_) => setShowEliminarProducto(true)}
              >
                Elimnar Producto
              </Button>
            </Col>
            <Col>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ marginRight: "1rem" }}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                style={{ textAlign: "end" }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Container>
      </StyledModal.Footer>
    </StyledModal>
  );
}

export default ModalEditarProductos;

import React, { useState } from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { IconDelete } from "../iconos/IconDelete";
import { IconEdit } from "../iconos/IconEdit";
const StyledTreeview = styled.div`
  ul {
    list-style: none;
  }
  li {
    cursor: pointer;
    .tree-node__branch:hover {
      background-color: #80808071;
    }
    .tree-leaf-list-item:hover {
      background-color: #80808071;
    }
  }
`;
function Treeview({ Datos, setSelected, categoriaSeleccionada, handleDelete }) {
  const auxDatos = flattenTree(Datos);
  const data = auxDatos.map((d) => ({
    ...d,
    name: d.name.split("?#%")[1],
    id_categoria: +d.name.split("?#%")[0],
  }));
  // console.log({ Datos, data });
  // console.log({ categoriaSeleccionada });
  const [expandedIds, setExpandedIds] = useState();
  const [inputs, setInputs] = useState();

  return (
    <div>
      <StyledTreeview>
        <div className="pt-3">
          {/* <Button
            className="my-2"
            onClick={(e) => {
              e.preventDefault();
              setExpandedIds([]);
            }}
          >
            Contraer todo
          </Button> */}
        </div>
        <TreeView
          data={data}
          expandedIds={expandedIds}
          defaultExpandedIds={[1]}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isDisabled,
            getNodeProps,
            level,
            handleExpand,
          }) => {
            return (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{
                  marginLeft: 40 * (level - 1),
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                <span
                  className="name"
                  onClick={(_) => {
                    setSelected(element);
                  }}
                  style={{
                    color:
                      categoriaSeleccionada?.id_categoria ===
                      element?.id_categoria
                        ? "green"
                        : "",
                    fontWeight:
                      categoriaSeleccionada?.id_categoria ===
                      element?.id_categoria
                        ? "bold"
                        : "",
                  }}
                >
                  {isBranch ? (!isExpanded ? "+" : "-") : ""} {element?.name}{" "}
                  {/* <Button
                    size="sm"
                    variant="link"
                    onClick={(_) =>
                      alert("edit" + JSON.stringify(categoriaSeleccionada))
                    }
                  >
                    <IconEdit />
                  </Button> */}
                  <Button
                    size="sm"
                    variant="link"
                    onClick={(_) =>
                      handleDelete({
                        id_categoria: categoriaSeleccionada.id_categoria,
                        nombre_categoria: categoriaSeleccionada.name,
                      })
                    }
                  >
                    <IconDelete />
                  </Button>
                </span>
              </div>
            );
          }}
        />
      </StyledTreeview>
    </div>
  );
}

export default Treeview;

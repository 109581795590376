import React, { useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import { urlApi } from "../servicios/api";
import { EliminarCanvas, EliminarModal } from "./ModalEliminar";

export const CarruselImagenes = ({
  Imagenes,
  width,
  height,
  btnDel,
  onDelete,
  nuevasImagenes,
}) => {
  const [show, setShow] = useState(false);
  const [imagenSeleccionada, setImagenSeleccionada] = useState();
  const [eliminarImagenes, setEliminarImagenes] = useState([]);
  // console.log({ Imagenes });
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const actualizarIndex = (_) => {
    const auxIndex = index - 1;
    setIndex(auxIndex < 0 ? 0 : auxIndex);
  };
  // console.log(Imagenes);

  // console.log({ index });
  // const [NuevasImagenes, setNuevasImagenes] = useState(
  //   nuevasImagenes?.map((img) => ({
  //     url: URL.createObjectURL(img),
  //     nombre: img.name,
  //   }))
  // );
  // console.log({ nuevasImagenes });

  return (
    <>
      {Imagenes[0] !== null && Imagenes[0]?.nombre ? (
        <Carousel variant="dark" activeIndex={index} onSelect={handleSelect}>
          {Imagenes.map(({ nombre, id, url }, i) => (
            <Carousel.Item key={i} style={{ textAlign: "center" }}>
              <Container>
                <Row>
                  <Col>
                    <small>{nombre?.split(".")[0]}</small>
                    &nbsp; &nbsp;
                    {btnDel && (
                      <Button
                        variant="outline-danger"
                        size="sm"
                        style={{
                          padding: "0.2rem 0.7rem",
                          margin: "0.5rem 0",
                        }}
                        onClick={() => {
                          setImagenSeleccionada({
                            id,
                            nombre,
                            url,
                          });
                          setShow(true);
                        }}
                      >
                        X
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <img
                    style={{ objectFit: "contain" }}
                    width={width ?? 120}
                    height={height ?? 120}
                    src={urlApi + url}
                    alt={i}
                  />
                </Row>
              </Container>
            </Carousel.Item>
          ))}
          {nuevasImagenes?.length
            ? nuevasImagenes
                ?.map((img) => ({
                  url: URL.createObjectURL(img),
                  nombre: img.name,
                }))
                .map(({ nombre, url }, i) => (
                  <Carousel.Item key={i} style={{ textAlign: "center" }}>
                    <Container>
                      <Row>
                        <Col>
                          <small>{nombre?.split(".")[0]}</small>
                          &nbsp; &nbsp;
                          {btnDel && (
                            <Button
                              variant="outline-danger"
                              size="sm"
                              style={{
                                padding: "0.2rem 0.7rem",
                                margin: "0.5rem 0",
                              }}
                              onClick={() => {
                                nuevasImagenes.splice(i, 1);
                                actualizarIndex();
                                // console.log(2, { nuevasImagenes });
                              }}
                            >
                              X
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <img
                          style={{ objectFit: "contain" }}
                          width={width ?? 120}
                          height={height ?? 120}
                          src={url}
                          alt={i}
                        />
                      </Row>
                    </Container>
                  </Carousel.Item>
                ))
            : ""}
        </Carousel>
      ) : (
        nuevasImagenes?.length && (
          <Carousel variant="dark">
            {nuevasImagenes
              ?.map((img) => ({
                url: URL.createObjectURL(img),
                nombre: img.name,
              }))
              .map(({ nombre, url }, i) => (
                <Carousel.Item key={i} style={{ textAlign: "center" }}>
                  <Container>
                    <Row>
                      <Col>
                        <small>{nombre?.split(".")[0]}</small>
                        &nbsp; &nbsp;
                        {btnDel && (
                          <Button
                            variant="outline-danger"
                            size="sm"
                            style={{
                              padding: "0.2rem 0.7rem",
                              margin: "0.5rem 0",
                            }}
                            onClick={() => {
                              nuevasImagenes.splice(i, 1);
                              actualizarIndex();
                              // console.log(2, { nuevasImagenes });
                            }}
                          >
                            X
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <img
                        style={{ objectFit: "contain" }}
                        width={width ?? 120}
                        height={height ?? 120}
                        src={url}
                        alt={i}
                      />
                    </Row>
                  </Container>
                </Carousel.Item>
              ))}
          </Carousel>
        )
      )}
      <EliminarModal
        show={show}
        entidad={"imagen"}
        mensaje={`¿Desea eliminar la imagen ${imagenSeleccionada?.nombre}?`}
        handleAceptar={(_) => {
          const EliminarImagen = [...eliminarImagenes, imagenSeleccionada];
          // console.log("->", EliminarImagen);
          setEliminarImagenes(EliminarImagen);
          onDelete(EliminarImagen);

          actualizarIndex();
          // console.log({ EliminarImagen });
          setShow(false);
        }}
        handleCancelar={(_) => setShow(false)}
      />
    </>
  );
};

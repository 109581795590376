import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

export const NavbarSidebar = ({ children, setPantalla }) => {
  const EXPANDOPTIONS = [false, "sm", "md", "lg", "xl", "xxl"];
  const expand = false
  const logOut = () => {
    localStorage.clear();
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };
  return (
    <>
      <Navbar key={expand} bg="light" expand={expand} fixed="top">
        <Container fluid style={{ paddingBottom: "1rem" }}>
          {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} /> */}
          <h3 style={{ marginRight: "auto", marginLeft: "1rem" }}>
            Provex - Sistema de Gestion Interna
          </h3>
          <Navbar.Brand href="#">
            {localStorage.getItem("nombre")}
            &nbsp; &nbsp;
            <Button size="sm" onClick={logOut}>
              Salir
            </Button>
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Button
                  variant="dark"
                  className="my-2"
                  onClick={(_) => setPantalla("productos")}
                >
                  Productos
                </Button>
                <Button
                  variant="dark"
                  onClick={(_) => setPantalla("categorias")}
                >
                  Categorias
                </Button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
        <div style={{ padding: "0 2rem", width: "100%" }}>{children}</div>
      </Navbar>
    </>
  );
};
